.DateRangePickerInput_arrow_svg {
    display: none;
}

.DateRangePickerInput {
    height: 30px;
}

.DateRangePicker {
    width: 95px !important;
    display: block;
    height: 30px;
}

#startDate {
    font-family: realist, sans-serif;
    font-size: 14px;
    font-weight: 500;
    padding: 2px;
    width: 75px !important;
}

.DateInput {
    width: 75px !important;
}

#endDate {
    display: none;
}
