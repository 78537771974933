.anime {
    padding: 10px;
    position: relative;
    cursor: pointer;
    text-align: center;
    font-weight: 600;
    color: white;
    min-height: 220px;
}

@keyframes gradientBG {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.icon:nth-child(2n) {
	animation-name: keyframes1;
	animation-iteration-count: infinite;
	transform-origin: 80% 20%;
}
 
.icon:nth-child(2n-1) {
	animation-name: keyframes2;
	animation-iteration-count: infinite;
	animation-direction: alternate;
	transform-origin: 50% 50%;
}
 
@keyframes keyframes1 {
	0% {
		transform: rotate(-0.6deg);
		animation-timing-function: ease-in;
	}
	50% {
		transform: rotate(0.5deg);
		animation-timing-function: ease-out;
	}
}
 
@keyframes keyframes2 {
	0% {
		transform: rotate(0.4deg);
		animation-timing-function: ease-in;
	}
	50% {
		transform: rotate(-0.7deg);
		animation-timing-function: ease-out;
	}
}